import React, { useEffect } from 'react';
import queryString from 'query-string';
import SimpleNotification from '../alerts/simple-notification';

const Layout = ({ children, classes }) => {
  // Check for the presence of a referral code and save it if we have one
  useEffect(() => {
    const parsed = window.location.search ? queryString.parse(window.location.search) : {};
    if (parsed && parsed.r) {
      // We have the referral value, remember it in localstorage
      window.localStorage.rcode = parsed.r;
    }
  }, []);

  return (
    <div className={`font-sans bg-gray-100 min-h-screen ${classes || ''}`}>
      <main>{children}</main>
      <SimpleNotification />
    </div>
  );
}

export default Layout;
