import React, { Fragment, useState, useEffect } from 'react'
import { Transition } from '@headlessui/react'
import { CheckCircleIcon, InformationCircleIcon, ExclamationIcon, ExclamationCircleIcon } from '@heroicons/react/outline'
// import { XIcon } from '@heroicons/react/solid'
import { useNotifications } from '../../contexts/notifications-provider';

function LabelIcon({ type }) {
  switch (type) {
    case 'error':
    case 'danger':
      return <ExclamationCircleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />;

    case 'success':
      return <CheckCircleIcon className="h-6 w-6 text-green-400" aria-hidden="true" />;
    case 'warning':
      return <ExclamationIcon className="h-6 w-6 text-yellow-600" aria-hidden="true" />;

    case 'info':
    default:
      return <InformationCircleIcon className="h-6 w-6 text-blue-500" aria-hidden="true" />;
  }
}

export default function SimpleNotification() {
  const [show, setShow] = useState(false);
  const { notifications, shiftNotification } = useNotifications();
  
  useEffect(() => {
    // console.log(notifications, 'this is notifications in the use effect');
    if (notifications.length) {
      const timeoutLength = notifications[0].timeout ? notifications[0].timeout : 5500;
      setShow(true);
      setTimeout(() => {
        shiftNotification();
      }, timeoutLength);
    }
    // return () => {};
  }, [notifications, shiftNotification]);


  const localAlert = notifications && notifications.length ? notifications[0] : null;
  const { title, body, type } = localAlert || {};
  const visible = show && !!localAlert;

  return (
    <>
      {/* Global notification live region, render this permanently at the end of the document */}
      <div
        aria-live="assertive"
        className="fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start"
        style={{ zIndex: 100000 }}
      >
        <div className="w-full flex flex-col items-center space-y-4 sm:items-end">
          {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
          {visible && (
            <Transition
              show={show}
              as={Fragment}
              enter="transform ease-out duration-300 transition"
              enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
              enterTo="translate-y-0 opacity-100 sm:translate-x-0"
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
                <div className="p-4">
                  <div className="flex items-start">
                    <div className="flex-shrink-0">
                      <LabelIcon type={type} />
                    </div>
                    <div className="ml-3 w-0 flex-1 pt-0.5">
                      {!!title && <p className="text-sm mb-1 font-medium text-gray-900">{title}</p>}
                      {!!body && <p className="text-sm text-gray-500">{body}</p>}
                    </div>
                    {/* <div className="ml-4 flex-shrink-0 flex">
                      <button
                        className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        onClick={() => {
                          setShow(false)
                        }}
                      >
                        <span className="sr-only">Close</span>
                        <XIcon className="h-5 w-5" aria-hidden="true" />
                      </button>
                    </div> */}
                  </div>
                </div>
              </div>
            </Transition>
          )}          
        </div>
      </div>
    </>
  )
}